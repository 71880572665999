<template>
	<component v-bind:is="componentName" v-if="findComponent"></component>
</template>
<script>
export default {
	name: 'ShowGlobal',
	data: () => ({
		findComponent: false,
		componentName: '',
	}),
	created() {
		this.setComponentName();
	},
	methods: {
		setComponentName() {
			const componentList = [
				{ componentName: 'registers-show-a', format: 'hseq-gam1-f-7' },
				{ componentName: 'registers-show-a', format: 'hseq-gam1-f-18' },
				{ componentName: 'registers-show-a', format: 'hseq-gam1-f-19' },
				{ componentName: 'registers-show-a', format: 'hseq-sso1-f-18' },
				{ componentName: 'registers-show-b', format: 'hseq-sso1-f-45' },
				{
					componentName: 'registers-show-hseq-sso-f-17',
					format: 'hseq-sso-f-17',
				},
				{
					componentName: 'registers-show-hseq-sso1-f-255',
					format: 'hseq-sso1-f-255',
				},
				{
					componentName: 'registers-show-hseq-sso1-f-5',
					format: 'hseq-sso1-f-5',
				},
				{
					componentName: 'registers-show-hseq-sso1-f-6',
					format: 'hseq-sso1-f-6',
				},
				{
					componentName: 'registers-show-hseq-sso1-f-87',
					format: 'hseq-sso1-f-87',
				},
				{
					componentName: 'registers-show-hseq-gam1-f-22',
					format: 'hseq-gam1-f-22',
				},
			];
			const foundComponent = componentList.find(
				(element) => element.format === this.$route.params?.inspectionId
			);
			if (foundComponent) {
				this.componentName = foundComponent.componentName;
				this.findComponent = true;
			}
		},
	},
	components: {
		RegistersShowA: () =>
			import(
				'@/components/inspections/formats/format-a/modules/show/RegistersShow.vue'
			),
		RegistersShowB: () =>
			import(
				'@/components/inspections/formats/hseq-sso1-f-45/modules/show/RegistersShow.vue'
			),
		RegistersShowHseqSsoF17: () =>
			import(
				'@/components/inspections/formats/hseq-sso-f-17/modules/show/RegistersShow.vue'
			),
		RegistersShowHseqSso1F255: () =>
			import(
				'@/components/inspections/formats/hseq-sso1-f-255/modules/show/RegistersShow.vue'
			),
		RegistersShowHseqSso1F6: () =>
			import(
				'@/components/inspections/formats/hseq-sso1-f-6/modules/show/RegistersShow.vue'
			),
		RegistersShowHseqSso1F5: () =>
			import(
				'@/components/inspections/formats/hseq-sso1-f-5/modules/show/RegistersShow.vue'
			),
		RegistersShowHseqSso1F87: () =>
			import(
				'@/components/inspections/formats/hseq-sso1-f-87/modules/show/RegistersShow.vue'
			),
		RegistersShowHseqGam1F22: () =>
			import(
				'@/components/inspections/formats/hseq-gam1-f-22/modules/show/RegistersShow.vue'
			),
	},
};
</script>
